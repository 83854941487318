import React from 'react';
import { Container } from '@axmit/clp-library';
import Typography from 'antd/es/typography';
import { useTranslation } from 'react-i18next';
import SEO from 'components/Seo';
import { graphql, useStaticQuery } from 'gatsby';
import { getEdgeTranslates, getTranslates, IEdge } from 'helpers/translate.helper';
import { FaqQuestionsList } from 'components/FaqQuestionsList';

export default () => {
  const data = useStaticQuery(query);
  const {
    i18n: { language }
  } = useTranslation();

  return (
    <div className="mb-30">
      <SEO title={getTranslates(data.allStrapiLesLandFaq, 'header', language)} />

      <Container>
        {data.allStrapiLesFaqSections?.edges?.map((section: IEdge) => (
          <React.Fragment key={section.node.id}>
            <Typography.Title level={2} className={`clp-typography-title clp-typography-title_size_md mb-30 ${section?.node?.order === 1 ? 'mt-30': 'mt-50'}`}>
              {getEdgeTranslates(section, 'header', language)}
            </Typography.Title>

            <FaqQuestionsList data={(section?.node?.les_faq_questions as unknown) as Record<string, string>[]} />
          </React.Fragment>
        ))}
      </Container>
    </div>
  );
};

const query = graphql`
  query {
    allStrapiLesFaqSections(sort: { fields: [order], order: ASC }) {
      edges {
        node {
          id
          header__en
          header__fr
          les_faq_questions {
            id
            header__en
            header__fr
            content__en
            content__fr
            order
          }
        }
      }
    }
    allStrapiLesLandFaq {
      edges {
        node {
          header__en
          header__fr
          par1__header__en
          par1__header__fr
          par1__content__1__en
          par1__content__1__fr
          par1__content__2__en
          par1__content__2__fr
          par1__content__3__en
          par1__content__3__fr
          par1__content__4__en
          par1__content__4__fr
          par1__content__5__en
          par1__content__5__fr
          par2__header__en
          par2__header__fr
          par2__content__en
          par2__content__fr
          par3__header__en
          par3__header__fr
          par3__content__en
          par3__content__fr
          par4__header__en
          par4__header__fr
          par4__content__en
          par4__content__fr
          par5__header__en
          par5__header__fr
          par5__content__en
          par5__content__fr
          par6__header__en
          par6__header__fr
          par6__content__en
          par6__content__fr
          par7__header__en
          par7__header__fr
          par7__content__en
          par7__content__fr
          par8__header__en
          par8__header__fr
          par8__content__en
          par8__content__fr
          par9__header__en
          par9__header__fr
          par9__content__en
          par9__content__fr
          par10__header__en
          par10__header__fr
          par10__content__en
          par10__content__fr
        }
      }
    }
  }
`;
